<template>
  <div class="supplier">
    <div class="title">
      <el-button type="primary" class="mb10" @click="goAdd">新增</el-button>
    </div>
    <div>
      <el-table
        :data="list"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="供应商名称"
        >
        </el-table-column>
        <el-table-column
          prop="subType"
          label="供应商类型"
        >
          <template slot-scope="{row}">
            {{ $changeObj(SUB_TYPE_OPTIONS)[row.subType]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
        >
          <template slot-scope="{row}">
            {{row.createTime | timeToDate}}
          </template>
        </el-table-column>
        <el-table-column
          prop="city"
          label="操作"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="goEdit(row)">编辑</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getSupplierList, updateSupplier } from '@/api/product'
import { SUB_TYPE_OPTIONS } from '@/js/constant'
export default {
  data() {
    return {
      list: [],
      pageSize: 20,
      beginIndex: 1,
      total: 0,
      SUB_TYPE_OPTIONS,
    }
  },
  mounted() {
    this.getSupplierList()
  },
  methods: {
    /** 获取 供应商列表 */
    async getSupplierList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        pageSize: this.pageSize,
        beginIndex: page,
      }
      const res = await getSupplierList(params)
      this.list = res.ldata
      this.total = res.mdata.total
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page
      this.getSupplierList()
    },
    /**删除 */
    del(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( async () => {
        const params = {
          number: row.number,
          deleteFlag: 1,
        }
       await updateSupplier(params);
       this.$message.success('删除成功')
       this.beginIndex = 1;
       this.getSupplierList()
      }).catch(() => {
              
      });
    },
   async goAdd() {
      this.$store.dispatch('login/checkHandle', '/electricity/battery/supplier/insert' ).then((flag) => {
        if(flag) {
          this.$router.push('/leaseholdMgmt/addSupplier')
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push('/leaseholdMgmt/addSupplier')
      })
    },
    goEdit(row) {
      this.$store.dispatch('login/checkHandle', '/electricity/battery/supplier/update' ).then((flag) => {
        if(flag) {
          this.$router.push(
            { path: '/leaseholdMgmt/addSupplier', 
              query: {
                name: row.name,
                subType: row.subType,
                type: 'edit',
                number: row.number,
                orgNo: row.orgNo,
              }
            }
          )
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push(
          { path: '/leaseholdMgmt/addSupplier', 
            query: {
              name: row.name,
              subType: row.subType,
              type: 'edit',
              number: row.number,
              orgNo: row.orgNo,
            }
          }
        )
      })

    }
  },
  components: {},
}
</script>

<style scoped lang='less'>
.supplier {
  padding-bottom: 80px;
}
</style>
